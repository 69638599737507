import ImageComponent from '../../images'
import Heading from "component/heading";

const Header = () => {

  return (
    <header data-testid="header-layout" className="dashboard_Header">
      <nav className="navbar navbar-expand-lg navbar-light py-0">
        <div className="d-flex align-items-center justify-content-between gap-3 w-100">
          <div className="logoWrapper">
            <ImageComponent path="/images/greensightLogo.png" />
          </div>
          <div className="d-flex gap-3 align-items-center pe-3">
            <ImageComponent path="/images/ekos-logistics.svg" />
            <div className="user-detail d-flex gap-2 align-items-center me-2">
              <ImageComponent imageName="profile-img-auto.png" className="pe-0"/>
              <div>
                <Heading content="Manmeet Narula" level="3" className="font-xxl-16 font-14 fw-semibold mb-0" />
                <Heading content="Sustainability Head" level="4" className="font-xxl-14 font-12 fw-normal mb-0" />
              </div>

            </div>
          </div>
        </div>

      </nav>
    </header>
  );
};

export default Header;
