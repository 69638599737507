import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTokenHeader, getErrorMessage } from "../../utils";
import userServices from "./userServices";
import { UserStateInterface } from "./userInterface";

const initialState: UserStateInterface = {
  isSuccess: false,
  isError: false,
  isLoading: false,
  data: null,
  error: null,
  userProfile: null,
};

export const getUserDetails = createAsyncThunk(
  "get/getUserDetails",
  async (_, thunkApi) => {
    try {
      return await userServices.getUserDetails(getTokenHeader());
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);


export const userReducer = createSlice({
  name: "user-details",
  initialState,
  reducers: {
    resetUserData: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.isSuccess = false;
        state.isLoading = true;
        state.userProfile = null;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.userProfile = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, _) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.userProfile = null;
      })
  },
});

export const { resetUserData } = userReducer.actions;
export default userReducer.reducer;
