import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "../auth/ProtectedRoute";

const DashBoardView = lazy(() => import("../pages/dashboard/DashboardView"));
const ErrorPage = lazy(() => import("../pages/error/ErrorView"));
/**
 * Component that defines all the routes for the website
 */
const EkosPortalRoute = () => {

  return (
    <Router basename="/">
      <Routes>
        
        {/* Protected routes for application panel*/}
        <Route element={<ProtectedRoute/>}>
          {/* DashboardView route*/}
          <Route path="/" key={"dashboard"} element={<DashBoardView />}/>
        
        </Route>
    
        {/* ErrorPage route */}
        <Route path="/page-not-found" element={
          <Suspense fallback={<span className="visually-hidden"></span>}>
            <ErrorPage />
          </Suspense>
        } />

        <Route
          path="*"
          element={
            <Suspense
              fallback={
                <div className="graph-loader  d-flex justify-content-center align-items-center">
                  <div className="spinner-border  spinner-ui d-flex justify-content-center align-items-center">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              }
            >
              <ErrorPage />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
};

export default EkosPortalRoute;
