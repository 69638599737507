import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CommonState } from "./commonDataInterface";

/**
 * Redux Slice for common data and functionality
 */

// Define the shape of the state

// Initial state
const initialState: CommonState = {
    isSuccess: false,
    isLoading: false,
    isLoadingProjectCount: false,
    message: null,
    pageTitle: null,
}

// Async Thunk to toggle sidebar status
export const updatePageTitle = createAsyncThunk("page/title", async (name: string) => {
    return name;
})

// Define the common data reducer
export const commonReducer = createSlice({
    name: "common-data",
    initialState,
    reducers: {
        resetCommonData: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(updatePageTitle.fulfilled, (state, action) => {
                state.pageTitle = action.payload;
            })

    }
})

// Export actions and reducer
export const { resetCommonData } = commonReducer.actions;
export default commonReducer.reducer;
