import { Navigate, Outlet } from "react-router-dom";
import HeaderLayout from "../component/layouts/header";
import { getBaseUrl } from "utils";
import { Suspense } from "react";
// import { useAppSelector } from "store/redux.hooks";
// Returns Is user is logged in or not

// Checks AuthRouters and redirects them to dashboar
export const AuthRouteCheck = ({ children, userDetails }: any) => {
  if (!userDetails?.data?.token) {
    return children;
  }
  else {
    return <Navigate to={getBaseUrl()} />;
  }
};

const ProtectedRoute = ({ roles }: any) => {
  // const { loginDetails } = useAppSelector((state) => state.auth);
  // if (!loginDetails?.data?.token) {
  //   return <Navigate to="/" />;
  // }

  return (
    <section className="insight_top_wrapper">
      <div className="mainDashboard position-relative">
        <div className="DashboardWrapper">
          <HeaderLayout />
          <div className="dashboardScreen">
            <Suspense
              fallback={
                <div className="graph-loader  d-flex justify-content-center align-items-center">
                  <div className="spinner-border spinner-ui d-flex justify-content-center align-items-center" >
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              }
            >
              <div className="container-fluid">
                <Outlet />
              </div>
            </Suspense>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProtectedRoute;
