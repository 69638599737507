import EkosPortalRoute from './routes/ekosPortalRoute';

const App = () => {
  return (
    <div data-testid="app-component">
        <EkosPortalRoute />
    </div>

  );
}

export default App;
