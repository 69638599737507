import axios from 'axios';

// Method for fetching user details.
const getUserDetails = async (token: any): Promise<any> => {
    try {
        // Send a GET request to the "/user-profile" endpoint with the provided token.
        return (await axios.get("/user-profile", token)).data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
};

const UserServices = {
    getUserDetails,
}

// Export the UserServices class for use in other parts of the application.
export default UserServices;
