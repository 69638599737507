import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardInterface } from "./dashboardInterface";
import { getErrorMessage, getTokenHeader } from "../../utils";
import dashboardService from "./dashboardService";

// Define the shape of the state

// Initial state
const initialState: DashboardInterface = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  isLoadingMatricsData: false,
  matricsData:null,
  isLoadingLocationByEmission: false,
  locationByEmissionData: null,
  isLoadingEmissionByDate: false,
  emissionByDateData:null,
  isLoadingGallonByDate: false,
  gallonPurchasedByData: null,
  isLoadingTransectionByDate: false,
  transectionByData: null,
  isLoadingFuelInfo: false,
  fuelInfoData: null,
  isLoadingFuelLocation: false,
  fuelLocationData: null,
  isLoadingVehicleByConsumption: false,
  vehicleByConsumptionData: null,
  isLoadingVehicleByEmission: false,
  vehicleByEmissionsData:null
};

// Async Thunks for changing region, lane, facility, and carrier

export const getMatrics = createAsyncThunk("get/dashboard/matrics", async (data: any, thunkApi: any) => {
  try {
    return await dashboardService.getMatricsApi(data, getTokenHeader());
  } catch (error: any) {
    const message: any = getErrorMessage(error);
    return thunkApi.rejectWithValue(message);
  }
}
);

export const getTopLocationByEmission = createAsyncThunk("get/location/emission", async (data: any, thunkApi: any) => {
  try {
    return await dashboardService.getTopLocationByEmissionApi(data, getTokenHeader());
  } catch (error: any) {
    const message: any = getErrorMessage(error);
    return thunkApi.rejectWithValue(message);
  }
}
);

export const getEmissionByDate = createAsyncThunk("get/emission/by/date", async (data: any, thunkApi: any) => {
  try {
    return await dashboardService.getEmissionByDateApi(data, getTokenHeader());
  } catch (error: any) {
    const message: any = getErrorMessage(error);
    return thunkApi.rejectWithValue(message);
  }
}
);

export const getGallonPurchasedByDate = createAsyncThunk("get/gallons/by/date", async (data: any, thunkApi: any) => {
  try {
    return await dashboardService.getEmissionByDateApi(data, getTokenHeader());
  } catch (error: any) {
    const message: any = getErrorMessage(error);
    return thunkApi.rejectWithValue(message);
  }
}
);

export const getTransectionByDate = createAsyncThunk("get/transection/by/date", async (data: any, thunkApi: any) => {
  try {
    return await dashboardService.getEmissionByDateApi(data, getTokenHeader());
  } catch (error: any) {
    const message: any = getErrorMessage(error);
    return thunkApi.rejectWithValue(message);
  }
}
);

export const getFuelTableInfo = createAsyncThunk("get/fuel/table/data", async (data: any, thunkApi: any) => {
  try {
    return await dashboardService.getFuelTableDataApi(data, getTokenHeader());
  } catch (error: any) {
    const message: any = getErrorMessage(error);
    return thunkApi.rejectWithValue(message);
  }
}
);

export const getFuelLocation = createAsyncThunk("get/fuel/location/data", async (data: any, thunkApi: any) => {
  try {
    return await dashboardService.getFuelLocationApi(data, getTokenHeader());
  } catch (error: any) {
    const message: any = getErrorMessage(error);
    return thunkApi.rejectWithValue(message);
  }
}
);

export const getVehicleByConsumption = createAsyncThunk("get/vehicle/fuel/consumption", async (data: any, thunkApi: any) => {
  try {
    return await dashboardService.getVehicleByConsumptionApi(data, getTokenHeader());
  } catch (error: any) {
    const message: any = getErrorMessage(error);
    return thunkApi.rejectWithValue(message);
  }
}
);

export const getVehicleByEmissions = createAsyncThunk("get/vehicle/fuel/emissions", async (data: any, thunkApi: any) => {
  try {
    return await dashboardService.getVehicleByEmissionsApi(data, getTokenHeader());
  } catch (error: any) {
    const message: any = getErrorMessage(error);
    return thunkApi.rejectWithValue(message);
  }
}
);


// Define the data management reducer
export const dashboardReducer = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetDashboardData: () => initialState,
  },
  extraReducers: (builder) => {
    // Handle fulfilled actions for changing region, lane, facility, and carrier
    builder
      .addCase(getMatrics.pending, (state) => {
        state.isLoadingMatricsData = true
      })
      .addCase(getMatrics.fulfilled, (state, action) => {
        state.isLoadingMatricsData = false
        state.matricsData = action.payload
      })
      .addCase(getMatrics.rejected, (state, action) => {
        state.isLoadingMatricsData = false
        state.matricsData = null
      })
      .addCase(getTopLocationByEmission.pending, (state) => {
        state.isLoadingLocationByEmission = true
      })
      .addCase(getTopLocationByEmission.fulfilled, (state, action) => {
        state.isLoadingLocationByEmission = false
        state.locationByEmissionData = action.payload
      })
      .addCase(getTopLocationByEmission.rejected, (state, action) => {
        state.isLoadingLocationByEmission = false
        state.locationByEmissionData = null
      })
      .addCase(getEmissionByDate.pending, (state) => {
        state.isLoadingEmissionByDate = true
      })
      .addCase(getEmissionByDate.fulfilled, (state, action) => {
        state.isLoadingEmissionByDate = false
        state.emissionByDateData = action.payload
      })
      .addCase(getEmissionByDate.rejected, (state, action) => {
        state.isLoadingEmissionByDate = false
        state.emissionByDateData = null
      })
      .addCase(getGallonPurchasedByDate.pending, (state) => {
        state.isLoadingGallonByDate = true
      })
      .addCase(getGallonPurchasedByDate.fulfilled, (state, action) => {
        state.isLoadingGallonByDate = false
        state.gallonPurchasedByData = action.payload
      })
      .addCase(getGallonPurchasedByDate.rejected, (state, action) => {
        state.isLoadingGallonByDate = false
        state.gallonPurchasedByData = null
      })
      .addCase(getTransectionByDate.pending, (state) => {
        state.isLoadingTransectionByDate = true
      })
      .addCase(getTransectionByDate.fulfilled, (state, action) => {
        state.isLoadingTransectionByDate = false
        state.transectionByData = action.payload
      })
      .addCase(getTransectionByDate.rejected, (state, action) => {
        state.isLoadingTransectionByDate = false
        state.transectionByData = null
      })
      .addCase(getFuelTableInfo.pending, (state) => {
        state.isLoadingFuelInfo = true
      })
      .addCase(getFuelTableInfo.fulfilled, (state, action) => {
        state.isLoadingFuelInfo = false
        state.fuelInfoData = action.payload
      })
      .addCase(getFuelTableInfo.rejected, (state, action) => {
        state.isLoadingFuelInfo = false
        state.fuelInfoData = null
      })
      .addCase(getFuelLocation.pending, (state) => {
        state.isLoadingFuelLocation = true
      })
      .addCase(getFuelLocation.fulfilled, (state, action) => {
        state.isLoadingFuelLocation = false
        state.fuelLocationData = action.payload
      })
      .addCase(getFuelLocation.rejected, (state, action) => {
        state.isLoadingFuelLocation = false
        state.fuelLocationData = null
      })
      .addCase(getVehicleByConsumption.pending, (state) => {
        state.isLoadingVehicleByConsumption = true
      })
      .addCase(getVehicleByConsumption.fulfilled, (state, action) => {
        state.isLoadingVehicleByConsumption = false
        state.vehicleByConsumptionData = action.payload
      })
      .addCase(getVehicleByConsumption.rejected, (state, action) => {
        state.isLoadingVehicleByConsumption = false
        state.vehicleByConsumptionData = null
      })
      .addCase(getVehicleByEmissions.pending, (state) => {
        state.isLoadingVehicleByEmission = true
      })
      .addCase(getVehicleByEmissions.fulfilled, (state, action) => {
        state.isLoadingVehicleByEmission = false
        state.vehicleByEmissionsData = action.payload
      })
      .addCase(getVehicleByEmissions.rejected, (state, action) => {
        state.isLoadingVehicleByEmission = false
        state.vehicleByEmissionsData = null
      })
  },
});

// Export the action and reducer
export const { resetDashboardData } = dashboardReducer.actions;
export default dashboardReducer.reducer;
