import axios from "axios";

/**
 * Retrieves vendor table data using a POST request.
 * @param userData - The user data for the request.
 * @param userToken - The user token for the request.
 * @returns The response data from the API call.
 */

const getMatricsApi = async (data: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("get-metrics", data, userToken);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};

const getTopLocationByEmissionApi = async (data: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("top-locations-by-emissions", data, userToken);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};

const getEmissionByDateApi = async (data: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("total-by-date-graph", data, { headers: { ...(userToken.headers), deniedCancle: true } });
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};

const getFuelLocationApi = async (data: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("get-fuel-locations", data, userToken);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};

const getFuelTableDataApi = async (data: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("get-fuels-table-data", data, userToken);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};

const getVehicleByConsumptionApi = async (data: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("get-top-vehicles-by-fuel-consumption", data, userToken);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};

const getVehicleByEmissionsApi = async (data: any, userToken: { headers: { Authorization: string } }): Promise<ApiResponse> => {
    try {
        const response = await axios.post("get-top-vehicles-with-highest-emissions", data, userToken);
        return response?.data;
    } catch (error: any) {
        throw error;
    }
};


const dashboardService = {
    getMatricsApi,
    getTopLocationByEmissionApi,
    getEmissionByDateApi,
    getFuelLocationApi,
    getFuelTableDataApi,
    getVehicleByConsumptionApi,
    getVehicleByEmissionsApi
};

export default dashboardService;